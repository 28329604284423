.hero {
    display: flex;
    justify-content: space-between;
}

.left-h {
    padding: 2rem;
    padding-top: 1rem;
    flex: 3;
    display: flex;
    flex-direction: column;
    gap: 2rem;  

}

.right-h {
    flex: 1;
    position: relative;
    background-color: orange;
    height: 1000px;
}

.the-best-ad
{
    margin-top: 4rem;
    background-color: #363d42;
    border-radius: 4rem;
    width: fit-content;
    padding: 20px 13px;
    text-transform: uppercase ;
    color: white;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.the-best-ad span {
    z-index: 2;
} 

.the-best-ad div 
{
    position: absolute;
    width: 5.4rem;
    height: 80%;
    background-color: orange;
    left: 8px;
    border-radius: 3rem;
    z-index: 1;
}

.hero-text {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    text-transform: uppercase;
    font-size: 4.5rem;
    font-weight: bold;
    color: white;
    text-overflow: inherit;
}

.hero-text div:nth-of-type(3) {
    font-size: 1rem;
    font-weight: 200;
    text-transform: uppercase;
    letter-spacing: 1px;
    width: 80%;
}

.figures {
    display : flex;
    gap: 2rem;;
}

.figures div {
   display: flex;
   flex-direction: column;

}

.figures div span:nth-of-type(1) {
    font-size: 2rem;
    color: white;
}

.figures div span:nth-of-type(2) {
    font-size: 1rem;
    color: grey;
    text-transform: none;
}

.hero-buttons {
    display: flex;
    gap: 1rem;
    font-weight: normal;
}

.hero-buttons button:nth-child(1) {
    color: white;
    background-color: orange;
    width: 8rem;
}

.hero-buttons button:nth-child(2) {
    color: white;
    background-color: transparent;
    width: 8rem;
    border: 2px solid orange;
}

.right-h button {
    width: 100%;
    position: absolute;
    color: white;
    font-size: large;
    height: 3rem;
    top: 1.35rem;
    background-color: transparent


}

.heart-rate {
     display: flex;
     flex-direction: column;
     gap: 1rem;
     width: 8rem;
     background-color: var(--darkGrey);
     padding: 1rem;
     border-radius: 5px;
     position: absolute;
     top: 7rem;
     right: 4rem;
     z-index: 2;
}
.heart-rate image{
    width: 2rem;
}

.heart-rate span:nth-child(2)
{
    color: gray;

}

.heart-rate span:nth-child(3)
{
    color: white;
    font-size: 1.5rem;
    
}

.hero-image {
    position: absolute;
    top: 10rem;
    right: 3rem;
    width: 23rem;
}

.hero_image_back {
    position: absolute;
    top: 4rem;
    right: 20rem;
    z-index: -1;
    width: 15rem;
}

.calories
{
    display: flex;
    gap: 2rem;
    background-color: var(--caloryCard);
    border-radius: 5px;
    padding: 1rem;
    width: fit-content;
    height: fit-content;
    position: absolute;
    top: 34rem;
    right: 28rem;
}

.calories image {
    width: 3rem;
}

.calories div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.calories span:nth-of-type(1) {
    color: gray;
}

.calories span:nth-of-type(2) {
    color: white;
}

@media screen and (max-width: 768px)
{
    .hero {
        display: flex;
        flex-direction: column;
    }

    .the-best-ad {
        margin-top: 0;
        font-size: small;
        align-self: center;
        transform: scale(0.8);
    }

    .right-h {
        display: none;
    }
}