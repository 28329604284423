.header {
    display: flex;
    justify-content: space-between;
}

.logo {
    height: 3rem;
    width: 10rem;
}

.header-menu {
    list-style: none;
    display: flex;
    color: white;
    gap: 2rem;
}

.header-menu li:hover {
    cursor: pointer;
    color: orange;
}

@media screen and (max-width: 768px){
    .header {
        display: flex;
        flex-direction: column;
    }

    .header img {
        align-self: center;
    }

    .header ul {
        align-self: center;
        transform: scale(0.8);
        padding-left: 1rem;
    }

    .calories {
        display: none;
    }

    .hero-image, .hero-image-back {
        position: absolute;
        display: block;
    }
}